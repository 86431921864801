<template>
  <b-card>
    <div>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        배팅 리스트
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="light-info"
          style="font-size: small; margin-right: 3px"
        >
          실시간
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: x-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="ml-2 mr-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1"
          >
            <label style="width: 41px">상태</label>
            <b-form-select
              id="site-select"
              v-model="fetchSelected1"
              :options="option1"
              class="divSelecter font-small-3"
              size="sm"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!--                </div>-->
          </b-col>
        </b-row>
      </div>
      <div class="ml-2 mr-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="col-sm-12"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="5"
            lg="5"
            md="5"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="배팅일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            class="right col-sm-12"
            cols="1"
            md="1"
            sm="1"
            style="margin-top: 4px"
          >
            <b-form-checkbox
              v-model="isExpandChecked1"
              checked="false"
              class="custom-control-primary float-right"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CornerRightDownIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="CropIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchRequestAlarmsBetAll"
        :fields="fields1"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        style="text-align: center; font-size: small"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler1"
      >
        <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ data.idx }}
        </template>
        <template #cell(site)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.site }}
          </div>
        </template>
        <template #cell(requestId)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 5rem; text-decoration: underline; text-underline-offset: 5px;"
            @click.stop="userClicked(data)"
          >
            {{ data.item.requestId }}({{ data.item.requestNickname }})
          </div>
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 5rem"
          >
            {{ categoryText(data.item.category) }}
          </div>
        </template>
        <template #cell(betDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 10rem"
          >
            {{ data.item.betDate }}
          </div>
        </template>
        <template #cell(sports)="data">
          <div style="text-align: center; min-width: 4rem;">
            {{ data.item.sports }}...
          </div>
        </template>
        <template #cell(fold)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 2rem"
          >
            {{ data.item.fold }}
          </div>
        </template>
        <template #cell(betCash)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.betCash).toLocaleString() }}
          </div>
        </template>
        <template #cell(totalOdds)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 3rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.totalOdds).toLocaleString() }}
          </div>
        </template>
        <template #cell(totalHitCash)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.totalHitCash).toLocaleString() }}
          </div>
        </template>
        <template #cell(requestIp)="data">
          <div
            v-b-tooltip.hover.bottom.v-danger="data.item.requestIp"
            size="sm"
            style="text-align: center;"
          >
            {{ truncateText(data.item.requestIp, 15) }}
          </div>
        </template>
        <template #cell(betResult)="data">
          <div
            v-if="data.item.betResult === 'WIN'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: limegreen"
          >
            적중
          </div>
          <div
            v-if="data.item.betResult === 'LOSS'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: red"
          >
            미적중
          </div>
          <div
            v-if="data.item.betResult === null"
            size="sm"
            style="text-align: center; min-width: 4rem; color: dodgerblue"
          >
            정산중
          </div>
          <div
            v-if="data.item.betResult === 'ALL TIE'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
          >
            배팅금 반환
          </div>
          <div
            v-if="data.item.betResult === 'CUSTOM CANCELED'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
          >
            배팅금 반환
          </div>
          <div
            v-if="data.item.betResult === 'USER CANCELED'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
          >
            배팅금 반환
          </div>
        </template>
        <template #cell(confirmed)="data">
          <div
            v-if="data.item.confirmed === 'REQUEST'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: gray"
          >
            승인요청
          </div>
          <div
            v-if="data.item.confirmed === 'YET'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: gray"
          >
            미결과
          </div>
          <div
            v-if="data.item.confirmed === 'NONE'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: gray"
          >
            미환급
          </div>
          <div
            v-if="data.item.confirmed === 'CONFIRMED'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: limegreen"
          >
            환급
          </div>
          <div
            v-if="data.item.confirmed === 'REJECT'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: red"
          >
            환급부결
          </div>
          <div
            v-if="data.item.confirmed === 'CUSTOM CANCELED'"
            size="sm"
            style="text-align: center; min-width: 6rem; color: yellow; font-size: small"
          >
            수동배팅취소
          </div>
          <div
            v-if="data.item.confirmed === 'USER CANCELED'"
            size="sm"
            style="text-align: center; min-width: 6rem; color: yellow; font-size: small"
          >
            회원배팅취소
          </div>
          <div
            v-if="data.item.confirmed === 'ALL TIE'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
          >
            전체 적특
          </div>
        </template>
        <template
          v-if="fetchRequestAlarmsBetAll.length > 0"
          slot="bottom-row"
        >
          <td
            v-if="fetchSelected1 === 'REQUEST'"
            style="background-color: #24233a; color:rgb(196,186,138)"
          />
          <td
            colspan="6"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            Total
          </td>
          <td
            class="text-center"
            style="background-color: #24233a;"
          >
            {{ Number(getTotal('fold')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('betCash')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('totalHitCash') / getTotal('betCash')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('totalHitCash')).toLocaleString() }}
          </td>
          <td style="background-color: #24233a; color:rgb(196,186,138)" />
          <td style="background-color: #24233a; color:rgb(196,186,138)" />
          <td style="background-color: #24233a; color:rgb(196,186,138)">
            <div
              v-if="fetchSelected1 === 'REQUEST' && this.selectedGroupIds.length > 0"
              class="text-center"
              style="color: #3df9ff"
            >
              {{ Number(this.selectedCash).toLocaleString() }}
            </div>
          </td>
          <td style="background-color: #24233a; color:rgb(196,186,138)">
            <div
              class="editClass"
              @click.stop
            >
              <span class="d-inline-flex">
                <div
                  v-if="fetchSelected1 === 'REQUEST' && this.selectedGroupIds.length > 0"
                  style="inline-size: max-content;"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    size="sm"
                    variant="outline-success"
                    style="font-size: small"
                    @click="submit('updateManyGameConfirmLoop')"
                  >
                    선택 승인
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    style="font-size: small;"
                    @click="submit('updateManyGameRejectLoop')"
                  >
                    선택 부결
                  </b-button>
                </div>
              </span>
            </div>
          </td>
        </template>
        <template #row-details="row">
          <b-card
            class="p-0"
            style="background-color: #212026;"
          >
            <RefundHitResultDetails :record="row.item._detailsData" @refreshParent="refreshParentMethod"/>
          </b-card>
        </template>
      </b-table>
      <!--          <pre>selected: {{ selectedGroupIds }}</pre>-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS_BET_ALL,
} from '@/store/notice/action'
import {
  UPDATE_GAME_CUSTOM_CANCEL,
  UPDATE_MANY_GAME_CONFIRM,
  UPDATE_MANY_GAME_REJECT,
  UPDATE_MATCH_CUSTOM_TIE,
} from '@/store/result/mutation'
import * as moment from 'moment-timezone'
import RefundHitResultDetails from '@/views/game/gameDeails/RefundHitResultDetails'
import router from "@/router";

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const noticeStore = createNamespacedHelpers('noticeStore')
const resultStore = createNamespacedHelpers('resultStore')

export default {
  components: {
    RefundHitResultDetails,
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tab: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      fetchSelected2: '',
      option1: [
        { value: 'ALL', text: '전체' },
        { value: 'REQUEST', text: '승인요청' },
        { value: 'YET', text: '미결과' },
        { value: 'NONE', text: '미환급' },
        { value: 'CONFIRMED', text: '환급완료' },
        { value: 'REJECT', text: '환급부결' },
        { value: 'CANCELED', text: '배팅취소' },
      ],
      option2: [
        { value: 'Sports-Prematch', text: '프리매치' },
        { value: 'Sports-Special', text: '스페셜' },
        { value: 'Sports-Inplay', text: '인플레이' },
      ],
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '오버언더' },
        { value: 'Over/Under', text: '오버언더' },
        { value: 'Over/Under by Games in Match', text: '오버언더' },
        { value: 'Total Maps', text: '오버언더' },
      ],
      perPage: 30,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'idx',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      check1: 'false',
      isHidden1: true,
      selectedRecord: null, // 선택된 record를 저장하기 위한 변수
      isExpandChecked1: false,
      isExpandChecked2: false,
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      selectedGroupIds: [],
      selectedCash: 0,
      fields1: [
        {
          key: 'idx', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5',
        },
        {
          key: 'betDate', sortable: false, label: '배팅일시', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col2',
        },
        {
          key: 'fold', sortable: false, label: '폴드', thClass: 'col3',
        },
        {
          key: 'betCash', sortable: false, label: '배팅금액', thClass: 'col3',
        },
        {
          key: 'totalOdds', sortable: false, label: '총배당', thClass: 'col3',
        },
        {
          key: 'totalHitCash', sortable: false, label: '적중금액', thClass: 'col3',
        },
        {
          key: 'requestIp', sortable: false, label: 'IP', thClass: 'col3',
        },
        {
          key: 'betResult', sortable: false, label: '적중', thClass: 'col3',
        },
        {
          key: 'confirmed', sortable: false, label: '상태', thClass: 'col6',
        },
      ],
    }
  },
  watch: {
    isExpandChecked1(newVal) {
      this.fetchRequestAlarmsBetAll.forEach(item => {
        this.$set(item, '_showDetails', newVal)
        this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
      })
    },
    fetchSelected1(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected1 = newVal
      this.fetchData1(newVal)
    },
    periodFrom() {
      this.fetchData1(this.fetchSelected1)
    },
    periodTo() {
      this.fetchData1(this.fetchSelected1)
    },
  },
  created() {
    this.init()
    this.$socket.on('requestAlarmsBet', data => {
      console.log('requestAlarmsBet: ', data)
      this.fetchData1(this.fetchSelected1)
    })
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBetAll: 'fetchRequestAlarmsBetAll',
    }),
    oddsTypeText() {
      return value => {
        const option = this.option3.find(option => option.value === value)
        return option ? option.text : value
      }
    },
    categoryText() {
      return value => {
        const option = this.option2.find(option => option.value === value)
        return option ? option.text : value
      }
    },
    allSelected: {
      get() {
        return this.fetchRequestAlarmsBetAll.length && this.selectedGroupIds.length === this.fetchRequestAlarmsBetAll.length
      },
      set(value) {
        const selected = []
        let selectedCash = 0
        if (value) {
          this.fetchRequestAlarmsBetAll.forEach(item => {
            selected.push(item.groupId)
            selectedCash += item.totalHitCash
          })
        }
        this.selectedGroupIds = selected
        this.selectedCash = selectedCash
      },
    },
  },
  mounted() {
    // 중복 Router 동작 우회로 EventBus 사용함
    // this.$EventBus.$on('QuickBtnFetchYET', param => {
    //   this.fetchSelected1 = 'ALL'
    // })
    // // 중복 Router 동작 우회로 EventBus 사용함
    // this.$EventBus.$on('QuickBtnFetchREQUEST', param => {
    //   this.fetchSelected1 = 'REQUEST'
    // })
    // 기본 조회
    if (this.$route.params.targetOption) {
      this.fetchSelected1 = 'ALL'
    } else {
      this.fetchSelected1 = 'ALL'
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBetAll: FETCH_REQUEST_ALARMS_BET_ALL,
    }),
    ...resultStore.mapActions({
      $updateMatchCustomTie: UPDATE_MATCH_CUSTOM_TIE,
      $updateGameCustomCancel: UPDATE_GAME_CUSTOM_CANCEL,
      $updateManyGameConfirm: UPDATE_MANY_GAME_CONFIRM,
      $updateManyGameReject: UPDATE_MANY_GAME_REJECT,
    }),
    init() {
      // this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      // this.periodTo = moment(new Date()).endOf('month').format(fmt1)
    },
    async fetchData1(fetchSelected) {
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY-MM-DD')} 00:00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY-MM-DD')} 23:59:59` : ''

      await this.$fetchRequestAlarmsBetAll({
        site: this.authSite,
        confirmed: fetchSelected,
        periodFrom,
        periodTo,
      })
      this.totalRows = this.fetchRequestAlarmsBetAll.length
    },
    // submit
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      try {
        if (target === 'updateManyGameConfirm') {
          await this.$updateManyGameConfirm({
            groupId: [data],
            userid: this.userData.userid,
            ip: this.userIp,
          })
        } else if (target === 'updateManyGameConfirmLoop') {
          await this.$updateManyGameConfirm({
            groupId: this.selectedGroupIds,
            userid: this.userData.userid,
            ip: this.userIp,
          })
        } else if (target === 'updateManyGameReject') {
          await this.$updateManyGameReject({
            groupId: [data],
            userid: this.userData.userid,
            ip: this.userIp,
          })
        } else if (target === 'updateManyGameRejectLoop') {
          await this.$updateManyGameReject({
            groupId: this.selectedGroupIds,
            userid: this.userData.userid,
            ip: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          // const args = { site: mySite }
          // this.$socket.emit('requestAlarms', args)
          this.fetchData1('REQUEST')
          this.fetchData2('NONE')
          this.selectedGroupIds = []
          this.selectedCash = 0
        })
      }
    },
    async rowClickHandler1(record, index, type) {
      console.log(type.target)
      // if (type.target.tagName === 'DIV') {
      //   return // <span> 태그 클릭시 함수 실행 중지
      // }
      // if (type.target.ariaColIndex !== '1' && type.target.tagName !== 'DIV') {
      if (type.target.ariaColIndex !== '1') {
        this.$set(record, '_showDetails', !record._showDetails) // _showDetails 설정
        this.$set(record, '_detailsData', record) // 각 항목의 상세 데이터를 설정
      }
    },
    rowClickHandler2(record, index) {
      // console.log('rowClickHandler2 : ', record)
      // console.log(index)
      // console.log(type.srcElement.parentElement.ariaColIndex)
    },
    userClicked(data) {
      localStorage.setItem('userView', JSON.stringify({ userid: data.item.requestId, site: data.item.site, nickname: data.item.requestNickname }))
      const url = router.resolve({ name: 'apps-user-view' }).href
      // window.open(url, '_blank')
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // Sleep function
    async sleep(ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {}
    },
    getTotal(field) {
      return this.fetchRequestAlarmsBetAll.reduce((acc, item) => acc + item[field], 0)
    },
    refreshParent() {
      // console.log(1111111111111)
      this.fetchData1('YET')
    },
    toggleAllRows() {
      if (this.allSelected) {
        this.selectedGroupIds = this.fetchRequestAlarmsBetAll.map(item => item.groupId)
      } else {
        this.selectedGroupIds = []
      }
    },
    updateTotalSum(totalHitCash, groupId) {
      if (this.selectedGroupIds.includes(groupId)) {
        // groupId가 selectedGroupIds 배열에 있으면 체크된 상태
        this.selectedCash += totalHitCash
      } else {
        // 그렇지 않으면 체크 해제된 상태
        this.selectedCash -= totalHitCash
      }
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    refreshParentMethod() {
      // 부모 컴포넌트를 리프레시하는 로직을 여기에 작성합니다
      console.log('Parent component refreshed')
      this.fetchData1(this.fetchSelected1) // 예시: 데이터 로드 메서드 호출
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
